import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { ThemeButton, TextInput } from '../../../../ui'
import { feedback } from '../../../../core/feedback'
import { requests } from '../../../../core/ajax'

const TEXT_AREA_LENGTH = 250

export const BlockAppDialog = ({ close, application, getApplication }) => {
  const { formatMessage } = useIntl()
  const { put } = requests

  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)

  const handleChangeForm = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }
  const handleSubmitForm = (e) => {
    e.preventDefault()
    const data = { ...formState }
    const id = application['id']
    setLoading(true)
    put(`/solicitudes/anular_solicitud/${id}`, data)
      .then(() => {
        setLoading(false)
        getApplication(application['id'])
        feedback('success', formatMessage({ id: 'pages.applications.feedback.1' }))
        close()
      })
      .catch(() => {
        console.error('Error canceling applicaiton')
        setLoading(false)
      })
  }

  return (
    <Box p={4}>
      <form onSubmit={handleSubmitForm}>
        <Box mb={2}>
          <TextInput
            name="motivo_anulacion_gestor"
            title={formatMessage({ id: 'pages.application.detail.dialogs.blockApp.title' })}
            value={formState['motivo_anulacion_gestor']}
            multiline
            minRows={5}
            inputProps={{ maxLength: TEXT_AREA_LENGTH }}
            onChange={handleChangeForm}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <Box mr={6}>
            <ThemeButton color="default" labelM="lg" onClick={close} disabled={loading}>
              {formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
          <ThemeButton
            type="submit"
            color="primary"
            labelM="lg"
            disabled={loading}
            loading={loading}
          >
            {formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </form>
    </Box>
  )
}
