import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

export const useSirCalls = () => {
  const { get, getById, put, putById, post, deleteCall } = requests
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getSirApps = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('/solicitudes/sir/tabla_gestores_sir', config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getSirApp = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        getById('/solicitudes', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putSirApp = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        putById('/solicitudes', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putSirUser = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        putById('/usuario_sir', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putSirBudget = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        putById('/presupuesto_sir', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const generateBudgetCall = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        post('/presupuesto_sir/generar_presupuesto', config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const sendBudgetCall = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        post('/send_mail/presupuesto_sir', config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const validatePaymentCall = useCallback(
    (id, state, config) =>
      new Promise((resolve, reject) => {
        put(`/solicitudes/cambiar_estado/${id}/${state}`, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            if (response.data?.message) {
              feedback('error', response.data.message)
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const cancelApplicationCall = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        put('/solicitudes/anular_solicitud', config)
          // put(`/solicitudes/cambiar_estado/${config.list_solicitudes_id[0]}/00`, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const rollbackApplicationCall = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        putById('/solicitudes/sir/rollback', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const postSirEventCall = useCallback((id, payload) => {
    return new Promise((resolve, reject) => {
      post(`/bitacora/add_comment/${id}`, payload)
        .then((response) => resolve(response))
        .catch(({ response }) => {
          return reject(response.status)
        })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getSirEventsCall = useCallback((id) => {
    return new Promise((resolve, reject) => {
      getById('/bitacora', id)
        .then((response) => resolve(response))
        .catch(({ response }) => {
          return reject(response.status)
        })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const postDocCall = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        post('/documento', config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getFiltersCall = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        get('/combo_box', {
          params: {
            parent_id: 'filtros_tabla',
            tabla: 'tabla_gestores_sir',
            gestor_id: id,
          },
        })
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const postFilterCall = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        post('/filtros_tabla/tabla_gestores_sir/', config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const deleteFilterCall = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        deleteCall('/filtros_tabla/tabla_gestores_sir/', {
          data: {
            nombre: id,
          },
        })
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getSirApps,
    getSirApp,
    putSirApp,
    putSirBudget,
    putSirUser,
    generateBudgetCall,
    sendBudgetCall,
    validatePaymentCall,
    cancelApplicationCall,
    rollbackApplicationCall,
    postSirEventCall,
    getSirEventsCall,
    postDocCall,
    getFiltersCall,
    postFilterCall,
    deleteFilterCall,
  }
}
